$(function(){
  const note_deletable = NOTE_DELETE;

  const delete_button = function(note_id){
    return $("<button>", {class: 'btn btn-outline-primary btn-sm'}).html('Delete').click(function(){
      $.ajax({
        url: `/notes/${note_id}.json`,
        method: 'DELETE',
        success: (response) => {
          $(this).parents('tr').remove();
        }
      });
    });
  };

  const note_row = function(note){
    var tr = $('<tr>');
    tr.append($('<tr>').append($('<td>').html(note.text)));
    var sub_tr = $('<tr>');
    const span = $("<span>", {style: 'color: grey; font-size: 0.6em;'}).html(`(${new Date(note.created_at)} - ${note.user_name})`);
    var td = $('<td style="border-top-style: hidden;">').html(span);
    if (note_deletable){
      const btnspan = $("<span>", {style: 'float: right;'}).html(delete_button(note.id));
      td.append(btnspan);
    }
    sub_tr.append(td);
    tr.append(sub_tr);
    return tr;
  };

  var offCanvas = document.getElementById('offcanvasNote');
  if (offCanvas){
    offCanvas.addEventListener('show.bs.offcanvas', function (event) {
      var btn = event.relatedTarget;
      var nform = $(offCanvas).find('.notes-form');
      var vals = {};

      // set title
      var title = btn.getAttribute('data-bs-title');
      if (title) $("#offcanvas-note-title").html(title);

      // get data from button element
      var attrs = ['ntype','parent_id','parent_c','project_id','persona_id'];
      attrs.forEach( attr => {
        var data_val = btn.getAttribute(`data-bs-${attr}`);

        if (data_val){
          vals[attr] = data_val;

          if (nform){
            var input = nform.find(`input[name="note[notable_attributes][${attr}]"]`);
            if (input) input.val(data_val);
          }
        }
      });

      if (vals['ntype'] == 'DgNote'){
        var nmajor = vals['parent_c'];
        var nminor = vals['parent_id'];
      }else if (vals['ntype'] == 'PersonaNote'){
        var nmajor = 'persona'
        var nminor = vals['persona_id'];
      }else{
        var nmajor = vals['project_id'];
        var nminor = vals['parent_c'];
      }

      // loads notes async
      $.ajax({
        type: "GET",
        url: `/notes/${vals['ntype']}/${nmajor}/${nminor}`,
        success: function(response){
          const trs = response
            .sort(function(a,b){
              var x = new Date(a.created_at);
              var y = new Date(b.created_at);
              return y.getTime() - x.getTime();
            })
            .map(r => note_row(r));
          $('#notes-tbody').html(trs);
        }
      });
    });
  }

  $(".notes-form").each( (i,nform) => {

    nform.addEventListener("ajax:success", function(event) {
      const note = event.detail[0];
      $('#notes-tbody').prepend(note_row(note));
      var txtarea = $(this).find('textarea');
      var msg = txtarea.val().trim();
      txtarea.val('');

      $(nform.parentElement).find('ul').prepend(`<li>${msg}</li>`);
    });

  });


});
