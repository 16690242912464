class CookieBar {
    constructor() {
      this.cookiesBar = document.getElementById('cookie-bar');
    }
  
    init() {
      if (this.cookiesAllowed()) {

      }

      this.addButtonBehaviors();
    }
  
    cookiesAllowed() {
      return Cookies.get('allow_cookies') === 'yes';
    }
  
    addButtonBehaviors() {
      if (!this.cookiesBar) {
        return;
      }
 
      this.cookiesBar.querySelector('.accept').addEventListener('click', () => this.allowCookies(true));
  
    }
  
    allowCookies(allow) {
        console.log("made it here");
      if (allow) {
        Cookies.set('allow_cookies', 'yes', {
          expires: 365
        });
  
      } else {
        Cookies.set('allow_cookies', 'no', {
          expires: 365
        });
      }
  
      this.cookiesBar.classList.add('hidden');
    }
  }
  
  window.onload = function() {
    const cookieBar = new CookieBar();
  
    cookieBar.init();
  }